import * as React from "react";
import Nav from "../Layout/Nav";
import MobileNav from "../Layout/MobileNav";

export default function Header({ open, openMenu }) {
  return (
    <div className={`${open ? "bg-white dark:bg-gray-900 relative z-30" : ""}`}>
      <Nav open={open} openMenu={openMenu} />
      {open && <MobileNav openMenu={openMenu} />}
    </div>
  );
}
