import { useState, useEffect } from "react";

export const useMode = () => {
  const [nightMode, setNightMode] = useState(false);

  const nightModeChanged = () => {
    setNightMode(!nightMode);
    localStorage.setItem("theme", !nightMode);
  };

  useEffect(() => {
    if (
      localStorage.theme === "false" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
      setNightMode(false);
    } else {
      document.documentElement.classList.remove("dark");
      setNightMode(true);
    }
  }, [nightMode]);

  return [nightMode, nightModeChanged];
};
