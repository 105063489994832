export const menu = [
  {
    name: "Home",
    url: "/",
    class:
      "dark:text-white font-medium hover:text-gray-500 dark:hover:text-gray-400",
  },
  {
    name: "About",
    url: "/about",
    class:
      "dark:text-white font-medium hover:text-gray-500 dark:hover:text-gray-400",
  },
  {
    name: "Works",
    url: "/works",
    class:
      "dark:text-white font-medium hover:text-gray-500 dark:hover:text-gray-400",
  },
  {
    name: "Awards",
    url: "/awards",
    class:
      "dark:text-white font-medium hover:text-gray-500 dark:hover:text-gray-400",
  },
  {
    name: "What I do",
    url: "/services",
    class:
      "dark:text-white font-medium hover:text-gray-500  dark:hover:text-gray-400",
  },
  {
    name: "Hire me",
    url: "https://tidycal.com/inahranova/20-minute-chating",
    class:
      "font-medium dark:text-white outline-none transition-colors bg-blue-500 hover:bg-blue-600 dark:bg-purple-500 dark:hover:bg-purple-600 rounded-full font-medium px-7 py-4 relative text-md z-20 text-white text-center shadow-lg shadow-blue-500/50 dark:shadow-purple-500/50",
  },
];
