import React, { Component } from "react";

export class BackgroundFixed extends Component {
  render() {
    return (
      <div className="balls fixed w-full h-screen top-0 z-0 filter blur-3xl opacity-30">
        <span className="w-60 h-60 bg-green-400 dark:bg-green-900 block rounded-full -inset-1/3	 top-10 absolute"></span>
        <span className="w-60 h-60 bg-yellow-200 dark:bg-pink-900 block rounded-full right-0 top-3 absolute"></span>
        <span className="w-60 h-60 bg-blue-600 dark:bg-blue-900 block rounded-full left-3.5 -bottom-20  absolute"></span>
        <span className="w-60 h-60 bg-purple-400 dark:bg-purple-900 block rounded-full inset-2/3 absolute"></span>
        <span className="w-60 h-60 bg-gradient-to-r from-purple-400 via-pink-500 to-red-500 block rounded-full absolute -inset-6"></span>
      </div>
    );
  }
}

export default BackgroundFixed;
