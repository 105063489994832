import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import BackgroundFixed from "./BackgroundFixed";

export default function Layout(props) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(false);
    document.body.classList.remove("overflow-hidden");
  }, []);

  const openMenu = () => {
    setOpen(!open);
    document.body.classList.toggle("overflow-hidden");
  };
  return (
    <main
      className={`main font-sofia dark:bg-gray-900 sm:px-10 lg:px-0 overflow-x-hidden`}
    >
      <Header open={open} openMenu={openMenu} />
      {props.children}
      <Footer />
      <BackgroundFixed />
    </main>
  );
}
