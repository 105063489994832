import React from "react";
import { Link } from "gatsby";
import { menu } from "../../data/menu";

export default function MobileNav(props) {
  const { openMenu } = props;
  return (
    <div className="mobile-nav border-t dark:border-gray-700  w-full h-full left-0 top-18 right-0 z-40 fixed block lg:hidden z-20 pt-8 bg-white dark:bg-gray-900">
      <ul className="text-center relative z-20 text-xl tracking-wide pb-20">
        {menu.map((link, x) => (
          <li key={x} className="py-5">
            <Link to={link.url} className={link.class} onClick={openMenu}>
              {link.name}
            </Link>
          </li>
        ))}
      </ul>
      <ul className="border-t dark:border-gray-700 pt-10 text-center tracking-wide">
        <li className="text-blue-500 dark:text-pink-500 mb-4">
          Currently available{" "}
          <span className="animate-pulse w-2 h-2 inline-block bg-blue-500 dark:bg-pink-300 rounded-full ml-2"></span>
        </li>
        <li>
          <a
            href="mailto:ina.hranova@gmail.com"
            target="_blank"
            className="opacity-85 text-base sm:text-lg md:text-xl hover:text-primary transition-colors dark:text-gray-50"
          >
            ina.hranova@gmail.com
          </a>
        </li>
      </ul>
    </div>
  );
}
