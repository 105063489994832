import React, { useState } from "react";
import { Link } from "gatsby";
import { menu } from "../../data/menu";
import { useMode } from "../../hooks/useMode";

export default function Nav({ open, openMenu }) {
  const [nightMode, nightModeChanged] = useMode();

  const toggleTheme = () => {
    nightModeChanged();
  };

  return (
    <>
      <div className="flex justify-between items-center  xl:w-4/5 mx-5 lg:mx-auto lg:px-10 py-4 lg:py-10 z-10 relative">
        <Link to="/" className="font-bitter font-bold text-4xl dark:text-white">
          Ina<b className="text-blue-400 dark:text-pink-400">.</b>
        </Link>
        <ul className="justify-around items-center mx-auto w-3/6 xl:w-3/5 hidden lg:flex">
          {menu.map((link, x) => (
            <li key={x}>
              <Link to={link.url} className={link.class}>
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
        <div className="flex justify-between items-center flex-row">
          <div
            className={`flex items-center rounded-full toggle-theme relative cursor-pointer outline-none focus:outline-none bg-gray-200 dark:bg-gray-800 ${
              nightMode ? "left" : "right"
            }`}
          >
            <button
              title="light theme"
              onClick={toggleTheme}
              className="toggle-container-button outline-none focus:outline-none bg-transparent font-bold relative z-20 transition-colors md:text-lg flex text-gray-700 items-center justify-center p-3"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 26 26"
                xmlns="http://www.w3.org/2000/svg"
                className="block text-gray-700 dark:text-white"
              >
                <path
                  d="M13 18c-2.7614 0-5-2.2386-5-5s2.2386-5 5-5 5 2.2386 5 5-2.2386 5-5 5zm0-13.8947a.6842.6842 0 01-.6842-.6842V.6842a.6842.6842 0 011.3684 0v2.7369A.6842.6842 0 0113 4.1053zm6.3189 2.5758a.7257.7257 0 010-1.0263l2.0526-2.0526a.7257.7257 0 111.0263 1.0263L20.3452 6.681a.7257.7257 0 01-1.0263 0zM21.8947 13a.6842.6842 0 01.6842-.6842h2.7369a.6842.6842 0 010 1.3684h-2.7369A.6842.6842 0 0121.8947 13zm-2.5758 6.3189a.7257.7257 0 011.0263 0l2.0526 2.0526a.7257.7257 0 11-1.0263 1.0263l-2.0526-2.0526a.7257.7257 0 010-1.0263zM13 21.8947a.6842.6842 0 01.6842.6842v2.7369a.6842.6842 0 01-1.3684 0v-2.7369A.6842.6842 0 0113 21.8947zM6.6811 19.319a.7257.7257 0 010 1.0263l-2.0526 2.0526a.7257.7257 0 11-1.0263-1.0263l2.0526-2.0526a.7257.7257 0 011.0263 0zM4.1053 13a.6842.6842 0 01-.6842.6842H.6842a.6842.6842 0 010-1.3684h2.7369A.6842.6842 0 014.1053 13zM6.681 6.6811a.7257.7257 0 01-1.0263 0L3.6022 4.6285a.7257.7257 0 111.0263-1.0263L6.681 5.6548a.7257.7257 0 010 1.0263z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>{" "}
            <button
              title="dark theme"
              onClick={toggleTheme}
              className="toggle-container-button outline-none focus:outline-none bg-transparent text-gray-700 font-bold p-3 relative z-20 transition-colors md:text-lg flex items-center justify-center"
            >
              <svg
                width="11"
                height="11"
                viewBox="0 0 11 11"
                xmlns="http://www.w3.org/2000/svg"
                className="text-gray-600 "
              >
                <path
                  d="M4.2716 10.4288C1.6043 9.7141.0214 6.9724.7361 4.3051 1.2858 2.2538 3.0342.8438 5.0258.627a4.1544 4.1544 0 00-.4905 1.129l-.1905.711c-.5916 2.208.7187 4.4774 2.9266 5.069 1.0629.2848 2.14.1288 3.0374-.3559-.8276 2.4926-3.467 3.9373-6.0372 3.2486z"
                  fill="currentColor"
                ></path>
              </svg>
            </button>
          </div>
          <div className="hamburger ml-5 relative lg:hidden" onClick={openMenu}>
            <span className={`dark:text-gray-50 ${open ? "hidden" : ""}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </span>
            <span className={`dark:text-gray-50 ${!open ? "hidden" : ""}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
